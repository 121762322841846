/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromMobileApp, tryParseJSONObject } from '../config/utils';

const sentMessageToApp = ({ messageToAppData, setMessageToAppData, setSentToAppMessage }) => {
  if (messageToAppData.sentToAppMessage.length === 0) return;
  const message = messageToAppData.sentToAppMessage[0];
  setSentToAppMessage({ ...message });
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler('bargesMessageHandler', ...[JSON.stringify({ ...message })]);
  }
  if (window.bargesMessageHandler) {
    window.bargesMessageHandler.postMessage(JSON.stringify({ ...message }));
  }
  if (window.webkit
    && window.webkit.messageHandlers
    && window.webkit.messageHandlers.bargesMessageHandler) {
    window.webkit.messageHandlers.bargesMessageHandler.postMessage(JSON.stringify({ ...message }));
  }
  setMessageToAppData({ delete: { ...message } });
};

const setReceivedMessageFromAppHandler = ({ e, setMessageToAppData, setReceivedMessage }) => {
  if (e.origin !== window.location.origin) return;
  const parsedMessage = tryParseJSONObject(e.data);
  if (parsedMessage) {
    setMessageToAppData({ receivedMessageFromApp: parsedMessage });
    setReceivedMessage(parsedMessage);
  }
};

function MessageToAppHandler({
  messageToAppData, setMessageToAppData, withOutPut
}) {
  const [sentToAppMessage, setSentToAppMessage] = useState({});
  const [receivedMessage, setReceivedMessage] = useState({});

  const setReceivedMessageFromApp = (e) => {
    setReceivedMessageFromAppHandler({ e, setMessageToAppData, setReceivedMessage });
  };

  useEffect(() => {
    if (fromMobileApp) {
      window.addEventListener('message', setReceivedMessageFromApp);
      return () => {
        window.removeEventListener('message', setReceivedMessageFromApp);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (messageToAppData.sentToAppMessage && fromMobileApp) {
      sentMessageToApp({ messageToAppData, setMessageToAppData, setSentToAppMessage });
    }
  }, [messageToAppData.sentToAppMessage]);

  const renderMessadeToApp = () => {
    if (!fromMobileApp || !withOutPut) return null;
    return (
      <div>
        receivedMessage:
        { JSON.stringify(receivedMessage) }
        <br />
        sentToAppMessage:
        { JSON.stringify(sentToAppMessage) }
      </div>
    );
  };

  return renderMessadeToApp();
}

MessageToAppHandler.propTypes = {
  messageToAppData: PropTypes.object.isRequired,
  setMessageToAppData: PropTypes.func.isRequired,
  withOutPut: PropTypes.bool
};

MessageToAppHandler.defaultProps = {
  withOutPut: false
};

export default MessageToAppHandler;
